@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  min-height: 100%;
}
body {
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}
.hide-scroll {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

@layer base {
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    --tw-ring-color: theme("colors.focus");
    border-color: theme("colors.focus");
  }

  [type="checkbox"],
  [type="radio"] {
    color: theme("colors.focus");
  }

  [type="checkbox"]:focus,
  [type="radio"]:focus {
    --tw-ring-color: theme("colors.focus");
  }

  button:focus,
  a:focus {
    outline-color: theme("colors.focus");
  }
}
