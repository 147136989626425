@font-face {
  font-family: Poppins;
  src: url(./assets//fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url(./assets//fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  src: url(./assets//fonts/Poppins-Light.ttf);
}


@import url('https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap');

* {
  font-family: "Poppins";
}

p {
	color: #172c66;
}
